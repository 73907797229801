@import "@campaign-portal/components-library/src/styles/alaris-global";
@import "custom";
@import "table";
@import "charts";

.layout {
	display:         grid;
	align-items:     center;
	justify-content: center;
	height:          100vh;
	overflow-y:      auto;
	background-size: cover;
}

.pointer {
	cursor: pointer;
}

.reset {
	@extend %as-reset-element;
	color:      var(--as-TextPrimary);
	text-align: start;
}


